 const links = {
        suadadmition:  {
            url : 'https://suad-admission.vercel.app/'
        },
        youtube:  {
            url : 'https://www.youtube.com/channel/UCQIfqlPgjM1XpYFNp8cZ6LA?app=desktop'
        },
        facebook:  {
            url : 'https://www.facebook.com/ahlussuffadars/'
        },
        instagram:  {
            url : 'https://www.instagram.com/ahlussuffadars/'
        },
    }

  export default links