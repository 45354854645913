import img4 from '../pages/img/servise4.jpg'
import img5 from '../pages/img/servise5.jpg'


export const ServiceCardData =[
    {
        img : img4,
        title : 'Robots in Space',
        para : 'Consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et.dolor sit amet'
    },
    {
        img : img5,
        title : 'Differentiated ',
        para : 'Consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et.dolor sit amet'
    },
    {
        img : img4,
        title : 'Analysis of Principal',
        para : 'Consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et.dolor sit amet'
    },
    {
        img : img5,
        title : 'The Sound of Silence',
        para : 'Consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et.dolor sit amet'
    },
    {
        img : img4,
        title : 'Formation Flying',
        para : 'Consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et.dolor sit amet'
    },
    {
        img : img5,
        title : 'Finger Gesture',
        para : 'Consectetur adipisicingelit, sed do eiusmod tempor incididunt ut labore et.dolor sit amet'
    }
]